import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-truck-row',
  templateUrl: './truck-row.component.html',
  styleUrls: ['./truck-row.component.scss'],
})
export class TruckRowComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
