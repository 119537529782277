//keep app module as light as possible

import { SharedModule } from './shared/shared.module';
import { AboutusModule } from './aboutus/aboutus.module';
import { SwiperModule } from 'swiper/angular';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { SplashpageComponent } from './components/splashpage/splashpage.component';
import { AppInsightsService } from './services/app-insights.service';
import { LandingComponent } from './pages/landing/landing.component';
import { GooglemapComponent } from './components/googlemap/googlemap.component';
import { MobilenavComponent } from './components/mobilenav/mobilenav.component';
import { DiffsManufacturedComponent } from './components/diffs-manufactured/diffs-manufactured.component';
import { AdvantagesComponent } from './components/advantages/advantages.component';
// from heritageweb2
import { Homepage2Component } from './components/homepage2/homepage2.component';
import { TruckRowComponent } from './components/homepage2/truck-row/truck-row.component';
import { LetsBuildRowComponent } from './components/homepage2/lets-build-row/lets-build-row.component';
import { HeroPicComponent } from './components/homepage2/hero-pic/hero-pic.component';
import { BeautifulDisplayComponent } from './components/homepage2/beautiful-display/beautiful-display.component';
import { FactorytrainedComponent } from './components/homepage2/factorytrained/factorytrained.component';
import { Topnavigation2Component } from './components/homepage2/topnavigation2/topnavigation2.component';
import { Bottomnavigation2Component } from './components/homepage2/bottomnavigation2/bottomnavigation2.component';
import { PagenotfoundComponent } from './components/pagenotfound/pagenotfound.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FooterComponent } from './components/footer/footer.component';
import {
  provideHttpClient,
  withInterceptorsFromDi,
} from '@angular/common/http';
import { MaterialModule } from './material-module';
import { RouterModule } from '@angular/router';
import { HomepageIntroComponent } from './components/homepage2/homepage-intro/homepage-intro.component';
import { TriplewideComponent } from './homes/manufactured/triplewide/triplewide.component';
import { AlaskaComponent } from './homes/manufactured/alaska/alaska.component';
import { PicturesComponent } from './components/pictures/pictures.component';
import { SinglewideComponent } from './homes/manufactured/singlewide/singlewide.component';
import { EnergystarComponent } from './homes/manufactured/energystar/energystar.component';
import { FormsModule } from '@angular/forms';
import { TempoComponent } from './homes/manufactured/tempo/tempo.component';
import { ReplacementsComponent } from './components/homepage2/replacements/replacements.component';
import { FloorplanModule } from './floorplan/floorplan.module';
import { FloorplansModule } from './floorplans/floorplans.module';
import { EasternWashingtonRoutingModule } from './homes/manufactured/eastern-washington/eastern-washington-routing.module';
import { DoublewideRoutingModule } from './homes/manufactured/doublewide/doublewide-routing.module';
import { LandpackageRoutingModule } from './homes/manufactured/landpackage/landpackage-routing.module';
import { SalesteamRoutingModule } from './salesteam/salesteam-routing.module';
import { Starthere2Component } from './components/homepage2/starthere2/starthere2.component';

@NgModule({
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  declarations: [
    AppComponent,
    PicturesComponent,
    FooterComponent,
    SplashpageComponent,
    LandingComponent,
    GooglemapComponent,
    MobilenavComponent,
    DiffsManufacturedComponent,
    AdvantagesComponent,
    Homepage2Component, //here on down from heritageweb2
    BeautifulDisplayComponent,
    TruckRowComponent,
    LetsBuildRowComponent,
    HeroPicComponent,
    FactorytrainedComponent,
    Topnavigation2Component,
    Bottomnavigation2Component,
    PagenotfoundComponent,
    HomepageIntroComponent,
    TriplewideComponent,
    AlaskaComponent,
    SinglewideComponent,
    EnergystarComponent,
    TempoComponent,
    ReplacementsComponent,
  ],
  bootstrap: [AppComponent],
  imports: [
    BrowserModule,
    RouterModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    MaterialModule,
    FormsModule,
    SwiperModule,
    SharedModule,
    FloorplanModule,
    FloorplansModule,
    EasternWashingtonRoutingModule,
    DoublewideRoutingModule,
    SalesteamRoutingModule,
    LandpackageRoutingModule,
    Starthere2Component
  ],
  providers: [AppInsightsService, provideHttpClient(withInterceptorsFromDi())],
})
export class AppModule {}
