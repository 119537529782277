import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { Starthere2Component } from './starthere2/starthere2.component';
@Component({
  selector: 'app-homepage2',
  templateUrl: './homepage2.component.html',
  styleUrls: ['./homepage2.component.scss'],
})
export class Homepage2Component implements OnInit {
  constructor(private meta: Meta, private title: Title) {}

  ngOnInit(): void {
    this.title.setTitle(
      'Heritage - Dealer selling new Manufactured Homes and Modular Homes in Washington State'
    );
    this.meta.updateTag({
      name: 'description',
      content:
        'Visit our Everett, WA showroom for the best manufactured homes, including singlewide, doublewide, and triplewide options. Enjoy competitive pricing and free shipping across Washington State. Open daily!',
    });
    this.meta.updateTag({
      name: 'keywords',
      content:
        'manufactured homes, mobile homes, home dealership, Everett WA, singlewide homes, doublewide homes, triplewide homes, affordable housing, home buying, manufactured home sales',
    });
  }
}
